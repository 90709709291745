import './App.css';

function App() {
  const year = new Date().getFullYear();

  return (
    <div className="app">
      <header className="App-header">
        <div className="image-container">
          <img src={'/profilepic.png'} className="profile-pic" alt="logo" />
        </div>
        <h1>Samir Mirza</h1>

        <h2>Chief Executive Officer</h2>
        <h2>Board Member</h2>
        <h2>Chief Human Resources and Organization Development Officer</h2>
      </header>

      <section>
        <h3>A Leader in Organization Development</h3>
        <p>
          Samir Mirza's remarkable journey as a leader in Organization
          Development, Operations Management and Human Resources began in 2004
          when he joined M1 Group, a renowned global conglomerate. As the
          Director of Human Resources and Organization Development and the
          architect of the corporate governance of the group, Samir's dedication
          and hard work made a significant impact on the organization. He led
          the development of an efficient organizational model that aligned
          perfectly with the group's strategy and values. He also introduced
          innovative human talent methodologies and streamlined business
          processes, contributing to the group's success.
        </p>
        <p>
          In 2017, Samir was promoted to Chief Human Resources and Organization
          Development Officer (Chief HROD Officer) of the group M1, where he
          took on additional responsibilities, including leading productivity
          analysis for the group's various subsidiaries and affiliates in
          different sectors around the world (Telecom, FinTech, Real Estate,
          Fashion, Retail, Manufacturing and Production, Travel, Utilities,
          Hospitalities, Education, Media, NGOs. Etc…).
        </p>
        <p>
          In 2021, Samir's exceptional talents and competencies were further
          recognized as he assumed several executive functions within the group.
          As Chairman of Leb24 online media broadcast, Chairman of the Soap
          Factory in the north of Lebanon, CEO of Azm Educational Campus and
          many others, he left an indelible mark on each organization, nurturing
          their growth and prosperity. Samir's transformative impact extended
          beyond just strategic planning and leadership; he was a driving force
          in managing and optimizing the operational processes. Focused on
          achieving excellence, he meticulously oversaw budgets and profit and
          loss statements for multiple operations, ensuring sustainable growth
          and prosperity across the organization.
        </p>
        <p>
          Before joining M1 Group, Samir's journey in Organization Development
          began at FTML (formerly known as Cellis) a telecom operator in
          Lebanon, where he was among the first in the region to implement Total
          Quality Management and Revenue Assurance systems, significantly
          improving efficiency.
        </p>
        <p>
          Between 1990 and 1997, at the start of his career, Samir worked in
          Paris as a Project Manager in charge of analysing, planning, managing
          and implementing the organization and processes of different software
          applications for several internationally known companies such as Air
          France, France Telecom and Bouygues.
        </p>
        <p>
          Samir's passion for learning led him to graduate from the INSA School
          of Engineering in Toulouse-France, with a degree in Electrical
          Engineering in 1990. He continued to expand his expertise through
          executive seminars at reputable institutions like Harvard and the
          London Business School.
        </p>
        <p>
          In addition to his illustrious career within prominent organizations,
          Samir also demonstrated his entrepreneurial spirit by founding an
          Organization Development consulting boutique. Leveraging his extensive
          expertise and insights gained from his leadership roles, Samir
          established this boutique as a platform to share his practical
          knowledge and hands-on approach with a diverse range of clients. With
          a focus on offering tailored solutions that align with the unique
          needs of each organization, Samir's boutique has quickly gained a
          reputation for delivering tangible results and fostering sustainable
          growth. His commitment to helping businesses thrive through effective
          Organization Development strategies showcases his dedication to making
          a lasting impact across various industries.
        </p>
        <p>
          Today, Samir Mirza remains an influential figure in the field of
          Organization Development, inspiring others with his practical approach
          and commitment to excellence. He is a board member and advisor in
          several organizations, a coach, and a trainer. His contributions have
          left a lasting impact on the organizations he has served, and he
          continues to be a beacon of innovation in the industry.
        </p>
      </section>

      <footer>
        <div className="in-touch">Get in touch</div>
        <div className="links-container">
          Say{' '}
          <a
            className="App-link"
            href="mailto:mail@samirtmirza.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hi
          </a>{' '}
          or find me on other platforms:{' '}
          <a
            className="App-link"
            href="https://www.linkedin.com/in/samir-mirza-84a26a2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </div>
        <div className="copyright">
          <div>Samir Mirza © {year}</div>
        </div>
      </footer>
    </div>
  );
}

export default App;
